<template>
  <form class="ls-form">
    <h3 style="text-align: center">Mobile Direct Recharge</h3>
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"></div>
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="form-group">
          <label for="catDescription">Recipient Number</label>
          <input class="form-control" type="number" v-model="phone" id="request_title" placeholder="0778493205" />
        </div>
        <div class="form-group">
          <label for="bundle_type">Select Topup Type</label>
          <select class="form-control" id="bundle_type" v-model="topupType">
            <option v-for="topupType in topupTypes" :key="topupType.value" :value="topupType.value">
              {{ topupType.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="network">Select Network</label>
          <select class="form-control" id="network" @change="
            filterNetwork($event);
          bundleType = null;
          " v-model="network">
            <option v-for="network in networks" :value="network.name" :key="network.code">
              {{ network.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="bundle_type">Select Bundle Type</label>
          <select class="form-control" id="bundle_type" v-model="bundleType" @change="filterBundles($event)">
            <option v-for="bundleType in networkBundleTypes" :key="bundleType.name">
              {{ bundleType.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="bundle">Select Bundle</label>
          <select class="form-control" id="bundle" v-model="selectedBundleId" @change="selectedBundle($event)">
            <option v-for="dataBundle in dataBundles" :key="dataBundle.BundleId" :value="dataBundle.BundleId">
              {{ dataBundle.Name.replace(/\$.*$/, "") }} ${{
            dataBundle.Amount / 100
          }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label>Bundle Details</label>
          <div class="row ls-bundles-container">
            <div class="col-xl-3 col-lg-4 col-md-6 col-12 equal-height" v-if="dataBundles.length > 0">
              <DataBundle :data-bundle="selectedBundleOb" :recepient="phone" :topupType="topupType" :balance="balance">
              </DataBundle>
            </div>
            <div v-if="dataBundles.length === 0" class="col-xl-12">
              <div class="p-4 text-info">No bundles found</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" v-if="false">
        <div class="form-group">
          <label>Select Bundle</label>
          <div class="row ls-bundles-container">
            <div class="col-xl-4 col-lg-4 col-md-6 col-12 equal-height" v-if="dataBundles.length > 0"
              v-for="dataBundle in dataBundles" :key="dataBundle.BundleId">
              <DataBundle :data-bundle="dataBundle" :recepient="phone" :balance="balance"></DataBundle>
            </div>
            <div v-if="dataBundles.length === 0" class="col-xl-12">
              <div class="p-4 text-info">No bundles found</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import DataBundle from "./DataBundle.vue";

export default {
  name: "MobileRecharge",
  components: { DataBundle },
  props: {
    balance: Number,
  },
  data() {
    return {
      bundles: [],
      dataBundles: [],
      selectedBundleOb: {},
      selectedBundleName: "0",
      selectedBundleId: "0",
      networkDataBundles: [],
      topupTypes: [
        {
          name: "Instant Topup in 0-30seconds ($0.30 Transaction Fees)",
          value: "Instant"
        },
        {
          name: "Near Instant Topup in under 5min (No Transaction Fees)",
          value: "Delayed"
        }
      ],
      networks: [
        {
          name: "Econet USD",
          code: 1,
        },
        {
          name: "Netone USD",
          code: 2,
        },
      ],
      network: "Econet USD",
      networkBundleType: null,
      networkBundleTypes: [],
      bundleTypes: [
        {
          name: "Bundle of Joy",
          network: "Econet USD",
        },
        {
          name: "Daily Data",
          network: "Econet USD",
        },
        {
          name: "Weekly Data",
          network: "Econet USD",
        },
        {
          name: "Private Wifi",
          network: "Econet USD",
        },
        {
          name: "WhatsApp",
          network: "Econet USD",
        },
        {
          name: "SMS",
          network: "Econet USD",
        },
        {
          name: "USD Airtime",
          network: "Netone USD",
        },
      ],
      dataBundleTypes: [],
      meta: null,
      isLoading: false,
      isBusy: false,
      isDeleting: false,
      key: "",
      topupType: "Delayed",
      bundleType: "Private Wifi",
      phone: "",
    };
  },
  mounted() {
    this.getDataBundles();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getDataBundles() {
      this.isLoading = true;
      this.$loading(true);
      axios
        .get("hot-recharge/data-bundles")
        .then((response) => {
          this.isLoading = false;
          this.bundles = response.data;
          this.filterNetwork();
          this.filterBundles();
          this.$loading(false);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$loading(false);
        });
    },

    filterNetwork() {
      this.dataBundles = this.bundles.filter((bundle) => bundle.Network === this.network);
      this.filterBundlesNetwork();
    },

    filterBundlesNetwork() {
      this.networkBundleTypes = this.bundleTypes.filter(
        (type) => type.network.toLowerCase().includes(this.network.toLowerCase())
      );
    },

    filterBundles() {
      this.dataBundles = this.bundles
        .filter((bundle) => bundle.Name.toLowerCase().includes(this.bundleType.toLowerCase()))
        .sort((a, b) => a.Amount - b.Amount); // Assuming numeric Amount
      this.selectedBundleOb = this.dataBundles[0];
      this.selectedBundleId = this.selectedBundleOb.BundleId;
    },

    // Assuming `selectedBundleName` can be set externally
    selectedBundle() {
      this.selectedBundleOb = this.dataBundles.find((bundle) => bundle.BundleId === this.selectedBundleId);
    },

    sortBundles(arr, propertyName, order = "ascending") {
      return arr.sort((a, b) => {
        const compareResult = a[propertyName] - b[propertyName];
        return order === "ascending" ? compareResult : -compareResult;
      });
    },
  },
};
</script>

<style scoped></style>
